export default {
  // 登录页
  loginPage: {
    area: 'Indicatif régional',
    areaMsg: 'Veuillez sélectionner un indicatif régional',
    phone: 'Téléphone',
    phoneMsg: 'Veuillez saisir votre numéro de téléphone',
    name: 'Pseudonyme',
    nameMsg: 'Veuillez saisir votre pseudonyme',
    password: 'Mot de passe',
    passwordMsg: 'Veuillez saisir votre mot de passe',
    confirmPassword: 'Confirmer le mot de passe',
    confirmpsd: 'Confirmer le mot de passe', // Note: Duplicate key, consider removing or renaming
    code: 'Code de référence',
    codeMsg: 'Veuillez saisir un code de référence',
    termes: 'En s\'inscrivant, vous acceptez nos termes et conditions',
    rappelez: 'Se souvenir de moi',
    register: 'S\'inscrire',
    login: 'Se connecter',
    account: 'Déjà un compte ?',
    gologing: 'Aller à la connexion',
    noAccount: 'Pas de compte ?',
    goregister: 'Aller à l\'inscription',
    pasdMage: 'Le mot de passe doit contenir 6 à 20 lettres et chiffres',
    loginSuccess: 'Connexion réussie',
    registerSuccess: 'Inscription réussie, veuillez se connecter',
    clause: 'Veuillez cocher pour accepter les termes!',
    noPasd: 'Les mots de passe ne correspondent pas!'
  },
  // 区号选择
  areaPage: {
    searchMsg: 'Veuillez saisir un indicatif international',
    sub: 'Confirmer'
  },
  // tabbar页
  tabbarPage: {
    home: 'Accueil',
    order: 'Commandes',
    trade: 'Transactions',
    team: 'Équipe',
    user: 'Utilisateur'
  },
  // 首页
  homePage: {
    income: 'Bénéfices cumulés',
    myService: 'Mes services',
    about: 'À propos de nous',
    help: 'Aide',
    customer: 'Service client',
    partner: 'Partenaires'
  },
  // 订单页
  orderPage: {
    order: 'Commande',
    notFinished: 'En cours',
    completed: 'Terminée',
    more: 'Plus d\'articles',
    orderNum: 'Numéro de commande',
    tradeTime: 'Date de transaction',
    amount: 'Montant',
    newTaskNum: 'Nombre de tâches en cours',
    expectedReturns: 'Bénéfices attendus',
    noData: 'Aucune donnée',
    placeOrder: 'Soumettre la commande',
    cancel: 'Annuler',
    close: 'Fermer',
    noBalance: 'Solde insuffisant',
    needRecharge: 'Recharge nécessaire',
    gorecharge: 'Souhaitez-vous effectuer une recharge ?'
  },
  // 交易页
  tradePage: {
    title: 'Transactions',
    account: 'Solde du compte',
    introduceTitle: 'Introduction aux transactions',
    introduceText: 'TiktokShop utilise les commentaires quotidiens sur TiktokShop pour présenter les produits à améliorer. Les utilisateurs cliquent simplement sur une commande et un signature de commande est automatiquement générée. Les utilisateurs payent le montant de la commande via la blockchain USDT, et obtiennent une commission supérieure à 0.6% à chaque fois, avec des récompenses élevées pour les commandes attribuées aléatoirement.',
    Completed: 'Nombre de transactions terminées',
    totalTask: 'Nombre total de tâches',
    start: 'Commencer la correspondance',
    dayProfit: 'Bénéfices du jour',
    tradeTime: 'Heure de transaction',
    price: 'Montant',
    profit: 'Bénéfices attendus',
    startTrade: 'Commencer les transactions',
    close: 'Annuler'
  },
  // 团队页
  teamPage: {
    title: 'Équipe',
    money: 'Montant',
    commission: 'Commission',
    receive: 'Recevoir',
    myTeam: 'Mon équipe',
    total: 'Total des membres',
    user: 'Utilisateur',
    contribution: 'Contribution (tâches)',
    status: 'État'
  },
  // 我的页面
  userPage: {
    noLogin: 'Non connecté',
    id: 'Numéro d\'identification',
    account: 'Mon compte',
    freezeAmount: 'Montant gelé',
    withdrawal: 'Retrait',
    recharge: 'Recharge',
    invitation: 'Inviter vos amis',
    invitationText: 'Pour chaque bénéfice gagné par un ami nouvellement inscrit, vous recevrez une commission proportionnelle.',
    nowInvitation: 'Inviter immédiatement',
    userInfo: 'Informations personnelles',
    financial: 'Détails financiers',
    rechargeRecord: 'Historique de recharge',
    withdrawalRecord: 'Historique de retrait',
    lang: 'Langue',
    out: 'Déconnexion',
    copy: 'Copié avec succès',
    load: 'Chargement...',
    outMsg: 'Êtes-vous sûr de vouloir vous déconnecter ?'
  },
  // 提现页面
  withdrawalPage: {
    title: 'Retrait',
    mode: 'Mode de paiement',
    address: 'Adresse de retrait',
    balance: 'Solde disponible',
    money: 'Montant du retrait',
    account: 'Montant effectivement reçu',
    serviceCharge: 'Frais de retrait de',
    submit: 'Soumettre',
    ipt: 'Le montant du retrait ne peut dépasser le solde disponible',
    isSub: 'Demande soumise',
    isAddress: 'Veuillez d\'abordlier à lier une adresse de retrait',
    noAccount: 'Montant disponible pour le retrait insuffisant'
  },
  // 充值页面
  rechargePage: {
    title: 'Recharge',
    submit: 'Soumettre',
    reMode: 'Mode de recharge',
    reMoney: 'Montant de la recharge',
    certificate: 'Télécharger la preuve',
    moneyMsg: 'Veuillez saisir un montant',
    qrCode: 'Recharge par code QR',
    copyClick: 'Cliquer pour copier',
    copy: 'Copié avec succès',
    success: 'Recharge réussie',
    submitMsg: 'Soumission réussie, en cours de vérification',
    load: 'Téléchargement en cours...',
    loadSucess: 'Téléchargement réussi',
    loadError: 'Le téléchargement a échoué, veuillez réessayer',
    isPz: 'Veuillez télécharger le certificat',
    rechargeMsg: "Veuillez choisir le mode de transfert suivant pour un montant équivalent, afin d'éviter des retards dans les contrôles financiers",
    detail: "Détails du rechargement",
    orderId: "Numéro de commande",
    time: "Heure",
    plase: 'Veuillez utiliser votre compte',
    tran: 'Transfert vers',
    toAcc: 'vers le compte',
    newAccount: 'Montant reçu actuellement',
    results: 'Obtenir les résultats'
  },
  // 邀请好友页
  InvitePage: {
    title: 'Inviter des amis',
    InviteCode: 'Code d\'invitation',
    InviteText: 'Invitez des amis et bénéficiez de gains élevés. Invitez vite!',
    copyClick: 'Cliquer pour copier',
    copy: 'Copié avec succès',
  },
  // 个人信息页
  userInfoPage: {
    title: 'Informations personnelles',
    phone: 'Numéro de téléphone',
    pasd: 'Mot de passe',
    edit: 'Modifier',
    yPasd: 'Veuillez saisir votre ancien mot de passe',
    yPasdMsg: 'L\'ancien mot de passe ne peut être vide!',
    newPasd: 'Veuillez saisir votre nouveau mot de passe',
    newPasdMsg: 'Le nouveau mot de passe ne peut être vide',
    nowPasd: 'Confirmer le nouveau mot de passe',
    nowPasdMsg: 'Le confirmation du nouveau mot de passe ne peut être vide',
    close: 'Annuler',
    submit: 'Confirmer',
    address: 'Veuillez saisir l\'adresse à modifier',
    sucess: 'Adresse de retrait modifiée avec succès',
    goLogin: 'Mot de passe modifié avec succès, veuillez se reconnecter',
  },
  // 资金明细页
  financialPage: {
    title: 'Détails des fonds',
    noMore: 'Plus d\'éléments',
    amount: 'Montant',
    recharge: 'Recharge',
    orderSy: 'Bénéfices des commandes',
    distributionSy: 'Bénéfices de la distribution',
    other: 'Autre',
    payment: 'Commande payée',
    withdrawal: 'Retrait',
    frozen: 'Gel',
    time: 'Date',
    noData: 'Aucune donnée pour l\'instant',
    refresh: 'Rafraîchissement réussi',
    Withdrawn: 'Retrait effectué',
    shsb: 'Échec de la validation',
    shz: 'En cours de validation'
  },
  // 充值记录页面
  rechargeRecordPage: {
    title: 'Enregistrements de recharge',
    noMore: 'Plus d\'éléments',
    amount: 'Montant',
    pendingPayment: 'En attente de paiement',
    paymentSec: 'Paiement réussi',
    paymentFail: 'Paiement échoué',
    refund: 'Remboursement',
    time: 'Date',
    noData: 'Aucune donnée pour l\'instant',
    refresh: 'Rafraîchissement réussi',
    textMsg: 'Veuillez impérativement effectuer le versement conformément à la virgule figurant à la fin du montant du paiement, sinon le montant ne sera pas crédité !'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: 'Enregistrements de retrait',
    noMore: 'Plus d\'éléments',
    amount: 'Montant',
    withdrawal: 'Retrait',
    time: 'Date',
    noData: 'Aucune donnée pour l\'instant',
    refresh: 'Rafraîchissement réussi'
  }
}