export default {
  // 登录页
  loginPage: {
    area: 'เขต',
    areaMsg: 'กรุณາเลือกเขต',
    phone: 'โทรศัพท์มือถือ',
    phoneMsg: 'กรุณາกรอกหมายเลขโทรศัพท์',
    name: 'ชื่อเล่น',
    nameMsg: 'กรุณากรอกชื่อเล่น',
    password: 'รหัสผ่าน',
    passwordMsg: 'กรุณາກรອກรหัสผ่าน',
    confirmPassword: 'ยืนยันรหัสผ่าน',
    confirmpsd: 'ยืนยันรหัสผ่าน', // Note: 'confirmpsd' should be consistent with 'confirmPassword'
    code: 'รหัสแนะนำ',
    codeMsg: 'กรุณາກรອກรหัสแนะนำ',
    termes: 'การสมัครหมายถึงคุณยอมรับຂໍ້ตกลงและเงื่อนໄขของเรา',
    rappelez: 'จดจำฉัน',
    register: 'สมัครสมาชิก',
    login: 'ล็อกอิน',
    account: 'มีบัญชีแล้วหรือไม่?',
    gologing: 'ไปล็อกอิน',
    noAccount: 'ไม่มีบัญชี?',
    goregister: 'ไปสมัครสมาชิก',
    pasdMage: 'รหัสผ่านต้องมี 6-20 ตัวอັກษรและตัวเลข',
    loginSuccess: 'ล็อกอินสำเร็จ',
    registerSuccess: 'สมัครสมาชิกสำเร็จ, กรุณາล็ອກอิน',
    clause: 'กรุณาเลือกยอมรับຂໍ້ตกลง!',
    noPasd: 'รหัสผ่านไม่ตรงกัน!'
  },
  // 区号选择
  areaPage: {
    searchMsg: 'กรุณາກรອກหมายเลขโทรศัพท์ระหว่างประเทศ',
    sub: 'ตกลง'
  },
  // tabbar页
  tabbarPage: {
    home: 'หน้าหลัก',
    order: 'คำสั່ງซื้อ',
    trade: 'การซื้อขาย',
    team: 'ทีม',
    user: 'ผู้ໃຊ້'
  },
  // 首页
  homePage: {
    income: 'รายได้รวม',
    myService: 'บริการของฉัน',
    about: 'เกี่ยวกับเรา',
    help: 'ช่วยเหลือ',
    customer: 'บริการลูกค้า',
    partner: 'พันธมิตร'
  },
  // 订单页
  orderPage: {
    order: 'คำสั່ງซื้อ',
    notFinished: 'ยังမပြီး',
    completed: 'สมบูรณ์',
    more: 'ไม่มีข้อมูลเพิ่มเติม',
    orderNum: 'หมายเลขคำสั່ງซื้อ',
    tradeTime: 'เวลาการซื้อขาย',
    amount: 'จำนวนเงิน',
    newTaskNum: 'จำนวนงานปัจจุบัน',
    expectedReturns: 'รายได้คาดการณ์',
    noData: 'ไม่มีข้อมูล',
    placeOrder: 'ส่งคำสั່ງซื้อ',
    cancel: 'ยกเลิก',
    close: 'ปิด',
    noBalance: 'ยอดเงินไม่เพียงพอ',
    needRecharge: 'ต้องชําระเงิน',
    gorecharge: 'ต้องไปชําระเงินหรือไม่'
  },
  // 交易页
  tradePage: {
    title: 'การซื้อขาย',
    account: 'ยอดเงินบัญชี',
    introduceTitle: 'แนะนำการซื้อขาย',
    introduceText: 'TiktokShop ใช้ข้อมູນที่ได้รับจาก TiktokShop ทุกวัน đểแสดงผลิตภัณฑ์ที่ต้องการปรับปรุงการขายของ TiktokShop. ผู้ໃช້ພຽງแค่คลิกคำสั່ງซื้อ, ระบบจะสร้างลายเซ็นคำสั່ງซื้ออัตโนมັດ. ผู้ໃช້ชําระเงินจำนวนคำสั່ງซื้อผ่านบล็อกเชน USDT, ทุกครั้งจะได้รับค่าคอมมิชชั่น 0.6% ขึ้นไป, ระบบจะแจกจ່າยลາງວັນจำนวนเงินใหญ่แบบสุ่ม.',
    Completed: 'จำนวนที่เสร็จสิ้น',
    totalTask: 'จำนวนงานทั้งหมด',
    start: 'เริ่มการจັບคู่',
    dayProfit: 'รายได้วันนี้',
    tradeTime: 'เวลาการซื้อขาย',
    price: 'จำนวนเงิน',
    profit: 'รายได้คาดการณ์',
    startTrade: 'เริ่มการซื้อขาย',
    close: 'ยกเลิก'
  },
  // 团队页
  teamPage: {
    title: 'ทีม',
    money: 'จำนวนเงิน',
    commission: 'ค่าคอมมิชชั่น',
    receive: 'รับ',
    myTeam: 'ทีมของฉัน',
    total: 'จำนวนทั้งหมด',
    user: 'ผู้ใช้',
    contribution: 'การร่วมทุน(งาน)',
    status: 'สถานะ'
  },
  // 我的页面
  userPage: {
    noLogin: 'ยังไม่ได้ล็อกอิน',
    id: 'หมายเลข',
    account: 'บัญชีของฉัน',
    freezeAmount: 'ยอดเงินที่ถูกระงັບ',
    withdrawal: 'ถອນเงิน',
    recharge: 'ชําระเงิน',
    invitation: 'เชิญเพื่อนของคุณ',
    invitationText: 'ทุกครั้งที่เพื่อนของคุณสมัครสมาชิกและມີรายได้, คุณจะได้รับค่าคอมมิชชั่นตามอัตราที่သတ်မှတ်',
    nowInvitation: 'เชิญทันที',
    userInfo: 'ข้อมูลส่วนตัว',
    financial: 'รายละเอียดการเงิน',
    rechargeRecord: 'รายการชําระเงิน',
    withdrawalRecord: 'รายการถອນเงิน',
    lang: 'ภาษา',
    out: 'ออกจากລ็ອກอິນ',
    copy: 'สำเร็จการคัดลอก',
    load: 'กำลังโหลด...',
    outMsg: 'ต้องการອອກจากລ็ອກอິນหรือไม่?'
  },
  // 提现页面
  withdrawalPage: {
    title: 'ถອນเงิน',
    mode: 'วิธีการชำระ',
    address: 'ที่อยู่สำหรับถອນเงิน',
    balance: 'ยอดคงเหลือ',
    money: 'จำนวนเงินถອນ',
    account: 'จำนวนเงินที่จริงได้รับ',
    serviceCharge: 'ค่าธรรมเนียมถອນเงิน',
    submit: 'ส่ง',
    ipt: 'จำนวนเงินถອນไม่สามารถเกินยອดคงเหลือได้',
    isSub: 'ได้ส่งคำร้องแล้ว',
    isAddress: 'กรุณາไปยังหน้าบทบาทที่อยู่สำหรับถອນเงินก่อน',
    noAccount: 'ยອดเงินถອນไม่เพียงพอ'
  },
  // 充值页面
  rechargePage: {
    title: 'ชำระเงิน',
    submit: 'ส่ง',
    reMode: 'วิธีการชำระเงิน',
    reMoney: 'จำนวนเงินชำระ',
    certificate: 'อัพโหลดหลักฐาน',
    moneyMsg: 'กรุณາใส่จำนวนเงิน',
    qrCode: 'สแกน QR Code ชำระเงิน',
    copyClick: 'คลิก đểคัดลอก',
    copy: 'คัดลອກสำเร็จ',
    success: 'ชำระเงินสำเร็จ',
    submitMsg: 'ส่งสำเร็จ, กำลังตรวจสອບ',
    load: 'កំពុងอัพโหลด...',
    loadSucess: 'อัพโหลดสำเร็จ',
    loadError: 'อัพโหลดล้มเหลว, กรุณາลองอีกครั้ง',
    isPz: 'กรุณาอัพโหลดหลັກฐาน',
    rechargeMsg: 'กรุณาเลือกวิธีการชำระเงินจำนวนเงินเทียบเท่า, เพื่อหลีกเลີងการตรวจสອບทางการเงิน',
    detail: 'รายละเอียດชำระเงิน',
    orderId: 'หมายเลขออર્ด',
    time: 'เวลา',
    plase: 'กรุณາໃช້บัญชี',
    tran: 'โอนເຂົ້າ',
    toAcc: 'ไปยังบัญชี',
    newAccount: 'จำนวนเงินที่ได้รับปัจจุบัน',
    results: 'ได้รับผลลัพธ์'
  },
  //邀请好友页
  InvitePage: {
    title: 'เชิญเพื่อน',
    InviteCode: 'รหัสเชิญ',
    InviteText: 'เชิญเพื่อน, ได้รับรายได้สูง,รีบไปเชิญ',
    copyClick: 'คลิก đểคัดลອກ',
    copy: 'คัดลອກสำเร็จ',
  },
  // 个人信息页
  userInfoPage: {
    title: 'ข้อมูลส่วนตัว',
    phone: 'หมายเลขโทรศัพท์',
    pasd: 'รหัสผ่าน',
    edit: 'แก้ไข',
    yPasd: 'กรุณາใส่รหัสผ่านเดิม',
    yPasdMsg: 'รหัสผ่านเดิมไม่สามารถเว้นว่าง!',
    newPasd: 'กรุณາใส่รหัสผ่านใหม่',
    newPasdMsg: 'รหัสผ่านใหม่ไม่สามารถเว้นว่าง!',
    nowPasd: 'ยืนยันรหัสผ่าน',
    nowPasdMsg: 'ยืนยันรหัสผ่านไม่สามารถเว้นว่าง!',
    close: 'ยกเลิก',
    submit: 'ตกลง',
    address: 'กรุณາใส่ที่อยู่ที่ต้องการแก้ไข',
    sucess: 'แก้ไขที่อยູ່ถອນเงินสำเร็จ',
    goLogin: 'แก้ไขรหัสผ่านสำเร็จ, กรุณາลୋଗอินใหม่',
  },
  // 资金明细页
  financialPage: {
    title: 'รายละเอียดเงิน',
    noMore: 'ไม่มีข้อมูลเพิ่มเติม',
    amount: 'จำนวนเงิน',
    recharge: 'ชำระเงิน',
    orderSy: 'รายได้จากออร์ด',
    distributionSy: 'รายได้จากการแจกจ่าย',
    other: 'อื่นๆ',
    payment: 'ชำระออร์ด',
    withdrawal: 'ถອນเงิน',
    frozen: 'ถูกแช่แข็ง',
    time: 'เวลา',
    noData: 'ไม่มีข้อมูล',
    refresh: 'รีเฟชสำเร็จ',
    Withdrawn: 'ถອນเงินสำเร็จ',
    shsb: 'ตรวจสອບล้มเหลว',
    shz: 'កំពុងตรวจสອບ'
  },
  // 充值记录页面
  rechargeRecordPage: {
    title: 'ประวัติชำระเงิน',
    noMore: 'ไม่มีข้อมูลเพิ่มເຕີม',
    amount: 'จำนวนเงิน',
    pendingPayment: 'รอชำระ',
    paymentSec: 'ชำระเงินสำเร็จ',
    paymentFail: 'ชำระเงินล้มเหลว',
    refund: 'คืนเงิน',
    time: 'เวลา',
    noData: 'ไม่มีข้อมูล',
    refresh: 'รีเฟชสำเร็จ',
    textMsg: 'โอนตามจຸດทศนิยมที่ตามมาจากจำนวนชำระ ไม่งั้นไม่ถືກโอน'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: 'ประวัติถອນเงิน',
    noMore: 'ไม่มีข้อมูลเพิ่มເຕີม',
    amount: 'จำนวนเงิน',
    withdrawal: 'ถອນเงิน',
    time: 'เวลา',
    noData: 'ไม่มีข้อมูล',
    refresh: 'รีเฟชสำเร็จ',
  }
}