export default {
  // 登录页
  loginPage: {
    area: '익역코드',
    areaMsg: '익역코드를 선택해주세요',
    phone: '휴대폰',
    phoneMsg: '휴대폰번호를 채워주세요',
    name: '닉칭',
    nameMsg: '닉칭을 채워주세요',
    password: '비밀번호',
    passwordMsg: '비밀번호를 채워주세요',
    confirmPassword: '비밀번호 확인',
    confirmpsd: '비밀번호 확인', // 'confirmpsd'와 'confirmPassword'은 같은 내용이므로 통일 필요 (이곳은 confirmpsd로 유지)
    code: '추천코드',
    codeMsg: '추천코드를 채워주세요',
    termes: '회원가입을 통해 저희의 규정 및 조건에 동의하는 것로 간주됩니다',
    rappelez: '로그인정보 기억',
    register: '회원가입',
    login: '로그인',
    account: '이미 계정이 존재하십니까?',
    gologing: '로그인으로 이동',
    noAccount: '계정이 없읍니까?',
    goregister: '회원가입으로 이동',
    pasdMage: '비밀번호는 6-20개의 암자와 숫자로 이루어져야 합니다',
    loginSuccess: '로그인 성공',
    registerSuccess: '회원가입 성공, 로그인해주세요',
    clause: '규정에 동의를 체크해주세요!',
    noPasd: '비밀번호가 일치하지 않습니다!'
  },
  // 区号选择
  areaPage: {
    searchMsg: '국제익역코드를 입력해주세요',
    sub: '확인'
  },
  // tabbar页
  tabbarPage: {
    home: '홈',
    order: '주문',
    trade: '거래',
    team: '팀',
    user: '회원',
  },
  // 首页
  homePage: {
    income: '累積수익',
    myService: '내 서비스',
    about: '회사소개',
    help: '도움',
    customer: '고객센터',
    partner: '협력업체'
  },
  // 订单页
  orderPage: {
    order: '주문',
    notFinished: '미완료',
    completed: '완료',
    more: '더이상 없음',
    orderNum: '주문번호',
    tradeTime: '거래시간',
    amount: '금액',
    newTaskNum: '현재 임무수',
    expectedReturns: '예상수익',
    noData: '데이터 없음',
    placeOrder: '주문접수',
    cancel: '취소',
    close: '닫기',
    noBalance: '잔액부족',
    needRecharge: '충전 필요',
    gorecharge: '충전으로 이동하시겠습니까'
  },
  // 交易页
  tradePage: {
    title: '거래',
    account: '계좌잔액',
    introduceTitle: '거래 소개',
    introduceText: 'TiktokShop은 TiktokShop 판매의 개선이 필요한 제품을 보여주기 위해 매일 TiktokShop의 피드백을 사용합니다. 사용자는 주문을 클릭만 하면 시스템은 자동으로 주문서명을 생성합니다. 사용자는 USDT 블록체인을 통해 주문금액을 지불하고, 0.6%이상의 수수료를 얻을 수 있으며, 시스템은 랜덤으로 큰 보너스 주문을 분배합니다.',
    Completed: '완료수',
    totalTask: '총임무수',
    start: '매칭시작',
    dayProfit: '당일수익',
    tradeTime: '거래시간',
    price: '금액',
    profit: '예상수익',
    startTrade: '거래시작',
    close: '취소'
  },
  // 团队页
  teamPage: {
    title: '팀',
    money: '금액',
    commission: '수수료',
    receive: '수령',
    myTeam: '내팀',
    total: '총인원',
    user: '회원',
    contribution: '기여(임무)',
    status: '상태'
  },
  // 我的页面
  userPage: {
    noLogin: '미로그인',
    id: '번호',
    account: '내 계정',
    freezeAmount: '얼리지 금액',
    withdrawal: '출금',
    recharge: '충전',
    invitation: '친구를 콥인ვ레이트',
    invitationText: '친구가 등록 후에 얻는 이익의 각 총액에 대해, 귀하는 一定비율의 수수료를 얻게 됩니다',
    nowInvitation: '이제 콥인વ레이트',
    userInfo: '개인정보',
    financial: '자금내역',
    rechargeRecord: '충전기록',
    withdrawalRecord: '출금기록',
    lang: '언어',
    out: '로그아웃',
    copy: '복사 성공',
    load: '로딩 중...',
    outMsg: '로그아웃 하시겠습니까?'
  },
  // 提现页面
  withdrawalPage: {
    title: '출금',
    mode: '지불방식',
    address: '출금주소',
    balance: '이용가능잔액',
    money: '출금액',
    account: '실제입금',
    serviceCharge: '출금수수료는',
    submit: '제출',
    ipt: '이용가능잔액 보다 큰 출금액은 불가능',
    isSub: '신청이 제출됨',
    isAddress: '먼저 출금주소를 묶어주세요',
    noAccount: '출금가능액이 부족'
  },
  // 充值页面
  rechargePage: {
    title: '충전',
    submit: '제출',
    reMode: '충전방식',
    reMoney: '충전액',
    certificate: '자격 증명 업로드',
    moneyMsg: '액수를 입력해주세요',
    qrCode: 'QR코드 충전',
    copyClick: '클릭복사',
    copy: '복사 성공',
    success: '충전 성공',
    submitMsg: '제출 성공, 검토 중',
    load: '업로드 중...',
    loadSuccess: '업로드 성공',
    loadError: '업로드에 실패했습니다. 다시 시도해 주세요.',
    isPz: '인증서를 업로드해주세요',
    rechargeMsg: '다음 방법을 선택하여 동액 전액을 송금하여 철저한 철사 검사를 피하십시오',
    detail: '충전 상세 사항',
    orderId: '주문 번호',
    time: '시간',
    plase: '계정을 사용해주세요',
    tran: '입금',
    toAcc: '계좌로',
    newAccount: '현재 수령된 금액',
    results: '결과를 얻음'
  },
  // 邀请好友页
  InvitePage: {
    title: '친구 콥인વ레이트',
    InviteCode: '콥인વ레이트 코드',
    InviteText: '친구를 콥인વ레이트, 큰 이익을 얻어보세요. 이제 콥인વ레이트!',
    copyClick: '클릭복사',
    copy: '복사 성공'
  },
  // 个人信息页
  userInfoPage: {
    title: '개인정보',
    phone: '휴대폰번호',
    pasd: '로그인비밀번호',
    edit: '수정',
    yPasd: '원래비밀번호를 입력해주세요',
    yPasdMsg: '원래비밀번호는 빈まま 될 수 없습니다!',
    newPasd: '새로운비밀번호를 입력해주세요',
    newPasdMsg: '새로운비밀번호는 빈まま 될 수 없습니다',
    nowPasd: '비밀번호확인',
    nowPasdMsg: '비밀번호확인은 빈まま 될 수 없습니다',
    close: '취소',
    submit: '확정',
    address: '수정주소를 입력해주세요',
    sucess: '출금주소 수정 성공',
    goLogin: '비밀번호 수정 성공, 다시 로그인해주세요'
  },
  // 资金明细页
  financialPage: {
    title: '자금내역',
    noMore: '더이상 없음',
    amount: '액수',
    recharge: '충전',
    orderSy: '주문이익',
    distributionSy: '배송이익',
    other: '기타',
    payment: '주문지불',
    withdrawal: '출금',
    frozen: '얼리지',
    time: '시간',
    noData: '데이터 없음',
    refresh: '리フレッシュ 성공',
    Withdrawn: '已出金',
    shsb: '검토실패',
    shz: '검토 중'
  },
  // 充值记录页面
  rechargeRecordPage: {
    title: '충전기록',
    noMore: '더이상 없음',
    amount: '액수',
    pendingPayment: '지불대기',
    paymentSec: '지불성공',
    paymentFail: '지불실패',
    refund: '환불',
    time: '시간',
    noData: '데이터 없음',
    refresh: '리フレッシュ 성공',
    textMsg: '결제액의 소수점을 정확히 맞춰 송금해주세요. 그렇지 않으면, 입금이 안 됩니다.'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: '출금기록',
    noMore: '더이상 없음',
    amount: '액수',
    withdrawal: '출금',
    time: '시간',
    noData: '데이터 없음',
    refresh: '리フレッシュ 성공'
  }
}