export default {
  // 登录页
  loginPage: {
    area: 'Vorwahl',
    areaMsg: 'Bitte Vorwahl auswählen',
    phone: 'Handynummer',
    phoneMsg: 'Bitte Handynummer eingeben',
    name: 'Nickname',
    nameMsg: 'Bitte Nickname eingeben',
    password: 'Passwort',
    passwordMsg: 'Bitte Passwort eingeben',
    confirmPassword: 'Passwort bestätigen',
    confirmpsd: 'Passwort bestätigen', // Duplikat von 'confirmPassword', sollte evtl. entfernt werden
    code: 'Empfehlungscode',
    codeMsg: 'Bitte Empfehlungscode eingeben',
    termes: 'Mit der Registrierung akzeptieren Sie unsere Allgemeine Geschäftsbedingungen',
    rappelez: 'Anmeldung merken',
    register: 'Registrieren',
    login: 'Anmelden',
    account: 'Bereits ein Konto?',
    gologing: 'Zum Anmelden',
    noAccount: 'Kein Konto?',
    goregister: 'Zum Registrieren',
    pasdMage: 'Passwort muss 6-20 Buchstaben und Zahlen enthalten',
    loginSuccess: 'Anmeldung erfolgreich',
    registerSuccess: 'Registrierung erfolgreich, bitte anmelden',
    clause: 'Bitte Allgemeine Geschäftsbedingungen akzeptieren!',
    noPasd: 'Passwörter stimmen nicht überein!'
  },
  // 区号选择
  areaPage: {
    searchMsg: 'Bitte internationale Vorwahl eingeben',
    sub: 'Bestätigen'
  },
  // tabbar页
  tabbarPage: {
    home: 'Startseite',
    order: 'Bestellungen',
    trade: 'Transaktionen',
    team: 'Team',
    user: 'Benutzer'
  },
  // 首页
  homePage: {
    income: 'Gesamtertrag',
    myService: 'Meine Dienstleistungen',
    about: 'Über uns',
    help: 'Hilfe',
    customer: 'Kundendienst',
    partner: 'Partner'
  },
  // 订单页
  orderPage: {
    order: 'Bestellung',
    notFinished: 'Nicht abgeschlossen',
    completed: 'Abgeschlossen',
    more: 'Keine weiteren Einträge',
    orderNum: 'Bestellungsnummer',
    tradeTime: 'Transaktionszeit',
    amount: 'Betrag',
    newTaskNum: 'Aktuelle Aufgabenanzahl',
    expectedReturns: 'Erwartete Erträge',
    noData: 'Keine Daten vorhanden',
    placeOrder: 'Bestellung aufgeben',
    cancel: 'Abbrechen',
    close: 'Schließen',
    noBalance: 'Kontostand zu niedrig',
    needRecharge: 'Aufladung erforderlich',
    gorecharge: 'Soll ich zur Aufladung gehen?'
  },
  // 交易页
  tradePage: {
    title: 'Transaktionen',
    account: 'Kontostand',
    introduceTitle: 'Transaktionsübersicht',
    introduceText: 'TiktokShop nutzt täglich Feedback von TiktokShop, um Produkte zu präsentieren, die Verbesserungen erfahren sollten. Nutzer klicken nur auf die Bestellung, und das System generiert automatisch eine Bestellungssignatur. Nutzer zahlen den Bestellungsbetrag über die USDT-Blockchain und erhalten jedes Mal eine Kommission von über 0.6%. Große Belohnungsbestellungen werden vom System zufällig zugewiesen.',
    Completed: 'Abgeschlossene Anzahl',
    totalTask: 'Gesamte Aufgabenanzahl',
    start: 'Beginne zuordnen',
    dayProfit: 'Heutiger Ertrag',
    tradeTime: 'Transaktionszeit',
    price: 'Betrag',
    profit: 'Erwartete Erträge',
    startTrade: 'Beginne Transaktion',
    close: 'Abbrechen'
  },
  // 团队页
  teamPage: {
    title: 'Team',
    money: 'Betrag',
    commission: 'Kommission',
    receive: 'Erhalten',
    myTeam: 'Mein Team',
    total: 'Gesamtzahl',
    user: 'Nutzer',
    contribution: 'Beitrag (Aufgaben)',
    status: 'Status'
  },
  // 我的页面
  userPage: {
    noLogin: 'Nicht angemeldet',
    id: 'ID',
    account: 'Mein Konto',
    freezeAmount: 'Einfrierbetrag',
    withdrawal: 'Auszahlung',
    recharge: 'Aufladung',
    invitation: 'Invitiere deine Freunde',
    invitationText: 'Du erhältst eine entsprechende Kommission von jedem Gewinn, den deine Freunde verdienen, nachdem sie sich registriert haben.',
    nowInvitation: 'Jetzt invitieren',
    userInfo: 'Persönliche Informationen',
    financial: 'Finanzübersicht',
    rechargeRecord: 'Aufladungshistorie',
    withdrawalRecord: 'Auszahlungshistorie',
    lang: 'Sprache',
    out: 'Abmelden',
    copy: 'Kopieren erfolgreich',
    load: 'Laden...',
    outMsg: 'Bist du sicher, dass du dich abmelden möchtest?'
  },
  // 提现页面
  withdrawalPage: {
    title: 'Auszahlung',
    mode: 'Zahlungsweise',
    address: 'Auszahlungsadresse',
    balance: 'Verfügbares Guthaben',
    money: 'Auszahlungsbetrag',
    account: 'Eigentlich eingehender Betrag',
    serviceCharge: 'Auszahlungsgebühr beträgt',
    submit: 'Abschicken',
    ipt: 'Auszahlungsbetrag darf nicht größer sein als verfügbares Guthaben',
    isSub: 'Antrag eingereicht',
    isAddress: 'Bitte zuerst eine Auszahlungsadresse binden',
    noAccount: 'Nicht auszahlbarer Betrag zu niedrig'
  },
  // 充值页面
  rechargePage: {
    title: 'Aufladung',
    submit: 'Abschicken',
    reMode: 'Aufladungsweise',
    reMoney: 'Aufladungsbetrag',
    certificate: 'Anmeldeinformationen hochladen',
    moneyMsg: 'Bitte Betrag eingeben',
    qrCode: 'QR-Code-Aufladung',
    copyClick: 'Klicken, um zu kopieren',
    copy: 'Kopieren erfolgreich',
    success: 'Aufladung erfolgreich',
    submitMsg: 'Erfolgreich eingereicht, in Bearbeitung',
    Load: 'Hochladen...',
    loadSucess: 'Upload erfolgreich',
    loadError: `Hochladen fehlgeschlagen, bitte versuchen Sie es erneut`,
    isPz: 'Bitte laden Sie das Zertifikat hoch',
    rechargeMsg: "Bitte wählen Sie die folgende Methode, um einen gleichwertigen Betrag zu überweisen und Verzögerungen in den Finanzprüfungen zu vermeiden.",
    detail: "Aufladedetails",
    orderId: "Auftragsnummer",
    time: "Zeit",
    plase: 'Bitte Nutzerkonto verwenden',
    tran: 'Einzahlung',
    toAcc: 'auf Konto',
    newAccount: 'Aktuell erhaltener Betrag',
    results: 'Ergebnis erhalten'
  },
  //邀请好友页
  InvitePage: {
    title: 'Freunde invitieren',
    InviteCode: 'Empfehlungscode',
    InviteText: 'Invitiere Freunde und genieße große Erträge. Los geht\'s!',
    copyClick: 'Klicken, um zu kopieren',
    copy: 'Kopieren erfolgreich'
  },
  // 个人信息页
  userInfoPage: {
    title: 'Persönliche Informationen',
    phone: 'Handynummer',
    pasd: 'Anmeldepasswort',
    edit: 'Bearbeiten',
    yPasd: 'Bitte Altes Passwort eingeben',
    yPasdMsg: 'Altes Passwort darf nicht leer sein!',
    newPasd: 'Bitte Neues Passwort eingeben',
    newPasdMsg: 'Neues Passwort darf nicht leer sein',
    nowPasd: 'Passwort bestätigen',
    nowPasdMsg: 'Passwortbestätigung darf nicht leer sein',
    close: 'Abbrechen',
    submit: 'Bestätigen',
    address: 'Bitte neue Adresse eingeben',
    sucess: 'Änderung der Auszahlungsadresse erfolgreich',
    goLogin: 'Passwortänderung erfolgreich, bitte erneut anmelden'
  },
  // 资金明细页
  financialPage: {
    title: 'Geldtransaktionsübersicht',
    noMore: 'Keine weitere Einträge',
    amount: 'Betrag',
    recharge: 'Aufladen',
    orderSy: 'Auftragsgewinn',
    distributionSy: 'Vertriebsgewinn',
    other: 'Andere',
    payment: 'Zahlung für Auftrag',
    withdrawal: 'Auszahlung',
    frozen: 'Einfrieren',
    time: 'Zeitpunkt',
    noData: 'Keine Daten vorhanden',
    refresh: 'Erfolgreich aktualisiert',
    Withdrawn: 'Auszahlung erfolgreich',
    shsb: 'Prüfung fehlgeschlagen',
    shz: 'In Prüfung'
  },
  // 充值记录页面
  rechargeRecordPage: {
    title: 'Aufladehistorie',
    noMore: 'Keine weitere Einträge',
    amount: 'Betrag',
    pendingPayment: 'Zahlung ausstehend',
    paymentSec: 'Zahlung erfolgreich',
    paymentFail: 'Zahlung fehlgeschlagen',
    refund: 'Rückerstattung',
    time: 'Zeitpunkt',
    noData: 'Keine Daten vorhanden',
    refresh: 'Erfolgreich aktualisiert',
    textMsg: 'Bitte immer gemäß dem Komma in der Zahlungsbetrag überweisen, sonst wird die Überweisung nicht gutgeschrieben!'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: 'Auszahlungshistorie',
    noMore: 'Keine weitere Einträge',
    amount: 'Betrag',
    withdrawal: 'Auszahlung',
    time: 'Zeitpunkt',
    noData: 'Keine Daten vorhanden',
    refresh: 'Erfolgreich aktualisiert'
  }
}