export default {
  // 登录页
  loginPage: {
    area: 'エリアコード',
    areaMsg: 'エリアコードを選択してください',
    phone: '電話番号',
    phoneMsg: '電話番号を入力してください',
    name: 'ニックネーム',
    nameMsg: 'ニックネームを入力してください',
    password: 'パスワード',
    passwordMsg: 'パスワードを入力してください',
    confirmPassword: 'パスワード確認',
    confirmpsd: 'パスワード確認',  // 注: 通常確認用のフィールド名はconfirmPasswordのままにするのが一般的
    code: '推薦コード',
    codeMsg: '推薦コードを入力してください',
    termes: '登録は当社の利用規約に同意を意味します',
    rappelez: 'メモリ機能',  // 注: 一般的には"Remember me"や"ログイン状態を保持"などが使われる
    register: '登録',
    login: 'ログイン',
    account: '既存アカウント？',
    gologing: 'ログインに行く',
    noAccount: 'アカウントありませんか？',
    goregister: '登録に行く',
    pasdMage: 'パスワードは6-20文字の英数字で入力してください',
    loginSuccess: 'ログイン成功',
    registerSuccess: '登録成功、ログインしてください',
    clause: '利用規約に同意をチェックしてください！',
    noPasd: 'パスワードが一致しません！'
  },
  // 区号选择
  areaPage: {
    searchMsg: '国際エリアコードを入力してください',
    sub: '決定'
  },
  // tabbar页
  tabbarPage: {
    home: 'ホーム',
    order: '注文',
    trade: '取引',
    team: 'チーム',
    user: 'ユーザー',
  },
  // 首页
  homePage: {
    income: '累計収益',
    myService: 'マイサービス',
    about: '会社情報',
    help: 'ヘルプ',
    customer: 'カスタマーサービス',
    partner: 'パートナー'
  },
  // 订单页
  orderPage: {
    order: '注文',
    notFinished: '未完了',
    completed: '完了',
    more: 'もうありません',
    orderNum: '注文番号',
    tradeTime: '取引時間',
    amount: '金額',
    newTaskNum: '現在のタスク数',
    expectedReturns: '予想収益',
    noData: 'データありません',
    placeOrder: '注文を出す',
    cancel: 'キャンセル',
    close: '閉じる',
    noBalance: '残高不足',
    needRecharge: 'チャージが必要です',
    gorecharge: 'チャージに行きますか？'
  },
  // 交易页
  tradePage: {
    title: '取引',
    account: 'アカウント残高',
    introduceTitle: '取引紹介',
    introduceText: 'TiktokShopは毎日TiktokShopのフィードバックを使用して、TiktokShopで売上が向上が必要な製品を展示します。ユーザーはただ注文をクリックするだけで、システムは自動的に注文署名を生成します。ユーザーはUSDTブロックチェーンを通じて注文金額を支払い、毎回0.6%以上の手数料を獲得し、システムはランダムで大額の報酬注文を割り当てます。',
    Completed: '完了数',
    totalTask: '総タスク数',
    start: 'マッチングを開始',
    dayProfit: '本日収益',
    tradeTime: '取引時間',
    price: '金額',
    profit: '予想収益',
    startTrade: '取引を開始',
    close: 'キャンセル'
  },
  // 团队页
  teamPage: {
    title: 'チーム',
    money: '金額',
    commission: '手数料',
    receive: '受領',
    myTeam: 'マイチーム',
    total: '総人数',
    user: 'ユーザー',
    contribution: '貢献（タスク）',
    status: '状態'
  },
  // 我的页面
  userPage: {
    noLogin: 'ログインしていません',
    id: 'ID',
    account: 'マイアカウント',
    freezeAmount: '凍結金額',
    withdrawal: '引き出し',
    recharge: 'チャージ',
    invitation: '友人を招待',
    invitationText: '友人が登録後にお金を稼ぐたびに、あなたは一定の割合の手数料を獲得します',
    nowInvitation: '今すぐ招待',
    userInfo: '個人情報',
    financial: '資金明細',
    rechargeRecord: 'チャージ履歴',
    withdrawalRecord: '引き出し履歴',
    lang: '言語',
    out: 'ログアウト',
    copy: 'コピー成功',
    load: 'ロード中...',
    outMsg: 'ログアウトを確定しますか？'
  },
  // 提现页面
  withdrawalPage: {
    title: '引き出し',
    mode: '支払い方法',
    address: '引き出し先アドレス',
    balance: '利用可能残高',
    money: '引き出し金額',
    account: '実際に到着',
    serviceCharge: '引き出し手数料は',
    submit: '提出',
    ipt: '引き出し金額は利用可能残高を超えてはいけません',
    isSub: '申請を提出',
    isAddress: 'まず引き出し先アドレスをバインドに行ってください',
    noAccount: '引き出し可能金額不足'
  },
  // 充值页面
  rechargePage: {
    title: 'チャージ',
    submit: '提出',
    reMode: 'チャージ方法',
    reMoney: 'チャージ金額',
    certificate: '認証情報のアップロード',
    moneyMsg: '金額を入力してください',
    qrCode: 'QRコードでチャージ',
    copyClick: 'クリックでコピー',
    copy: 'コピー成功',
    success: 'チャージ成功',
    submitMsg: '提出成功、審査中',
    load: 'アップロード中...',
    loadSucess: 'アップロード成功',
    loadError: 'アップロードに失敗しました。もう一度お試しください',
    isPz: '証明書をアップロードしてください',
    rechargeMsg: '以下の方法を選択して同等金額を振込み、財務審査の遅延を避けてください',
    detail: '充値詳細',
    orderId: '注文番号',
    time: '時間',
    plase: 'アカウントを使用してください',
    tran: '振り込み',
    toAcc: '口座に',
    newAccount: '目前に受領した金額',
    results: '結果を取得'
  },
  //邀请好友页
  InvitePage: {
    title: '友人を招待',
    InviteCode: '招待コード',
    InviteText: '友人を招待し、大額の収益を享受。早く招待しましょう！',
    copyClick: 'クリックでコピー',
    copy: 'コピー成功',
  },
  // 个人信息页
  userInfoPage: {
    title: '個人情報',
    phone: '電話番号',
    pasd: 'ログインパスワード',
    edit: '編集',
    yPasd: '旧パスワードを入力してください',
    yPasdMsg: '旧パスワードを空にしてはいけません！',
    newPasd: '新パスワードを入力してください',
    newPasdMsg: '新パスワードを空にしてはいけません',
    nowPasd: 'パスワード確認',
    nowPasdMsg: 'パスワード確認を空にしてはいけません',
    close: 'キャンセル',
    submit: '決定',
    address: '編集アドレスを入力してください',
    sucess: '引き出し先アドレスを編集成功',
    goLogin: 'パスワードを編集成功、再ログインしてください',
  },
  // 资金明细页
  financialPage: {
    title: '資金明細',
    noMore: 'もうありません',
    amount: '金額',
    recharge: '充値',
    orderSy: '注文利益',
    distributionSy: '配布利益',
    other: 'その他',
    payment: '注文支払い',
    withdrawal: '引き出し',
    frozen: '凍結',
    time: '時間',
    noData: 'データありません',
    refresh: '更新成功',
    Withdrawn: '引き出し済み',
    shsb: '審査不合格',
    shz: '審査中'
  },
  // 充值记录页面
  rechargeRecordPage: {
    title: '充値記録',
    noMore: 'もうありません',
    amount: '金額',
    pendingPayment: '支払い待ち',
    paymentSec: '支払い成功',
    paymentFail: '支払い失敗',
    refund: '返金',
    time: '時間',
    noData: 'データありません',
    refresh: '更新成功',
    textMsg: 'お支払金額の小数点に厳密に従って振り込むようお願いいたします。そうでないと、到着できません。'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: '引き出し記録',
    noMore: 'もうありません',
    amount: '金額',
    withdrawal: '引き出し',
    time: '時間',
    noData: 'データありません',
    refresh: '更新成功'
  }
}