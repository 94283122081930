export default {
  // 登录页
  loginPage: {
    area: '区号',
    areaMsg: '请选择区号',
    phone: '手机',
    phoneMsg: '请填写手机号',
    name: '呢称',
    nameMsg: '请填写昵称',
    password: '密码',
    passwordMsg: '请填写密码',
    confirmPassword: '确认密码',
    confirmpsd: '确认密码',
    code: '推荐码',
    codeMsg: '请填写推荐码',
    termes: '注册即表示您同意我们的条款和条件',
    rappelez: '记住我',
    register: '注册',
    login: '登录',
    account: '已有账户？',
    gologing: '去登录',
    noAccount: '没有账号？',
    goregister: '去注册',
    pasdMage: '密码需6-20个字母和数字',
    loginSuccess: '登录成功',
    registerSuccess: '注册成功, 请登录',
    clause: '请勾选同意条款!',
    noPasd: '密码不一致!'
  },
  // 区号选择
  areaPage: {
    searchMsg: '请输入国际区号',
    sub: '确定'
  },
  // tabbar页
  tabbarPage: {
    home: '首页',
    order: '订单',
    trade: '交易',
    team: '团队',
    user: '用户',
  },
  // 首页
  homePage: {
    income: '累计收益',
    myService: '我的服务',
    about: '关于我们',
    help: '帮助',
    customer: '客服',
    partner: '合作伙伴'
  },
  // 订单页
  orderPage: {
    order: '订单',
    notFinished: '未完成',
    completed: '已完成',
    more: '没有更多了',
    orderNum: '订单号',
    tradeTime: '交易时间',
    amount: '金额',
    newTaskNum: '当前任务数',
    expectedReturns: '预期收益',
    noData: '暂无数据',
    placeOrder: '提交订单',
    cancel: '取消',
    close: '关闭',
    noBalance: '余额不足',
    needRecharge: '需要充值',
    gorecharge: '是否前往充值'
  },
  // 交易页
  tradePage: {
    title: '交易',
    account: '账户余额',
    introduceTitle: '交易介绍',
    introduceText: 'TiktokShop每天使用TiktokShop的反馈来展示TiktokShop销售的需要改进的产品。用户只需点击订单，系统就会自动生成订单签名。用户通过USDT区块链支付订单金额，每次可获得0.6%以上的佣金，系统随机分配大额奖励订单。',
    Completed: '已完成数',
    totalTask: '总任务数',
    start: '开始匹配',
    dayProfit: '今日收益',
    tradeTime: '交易时间',
    price: '金额',
    profit: '预期收益',
    startTrade: '开始交易',
    close: '取消'
  },
  // 团队页
  teamPage: {
    title: '团队',
    money: '金额',
    commission: '佣金',
    receive: '领取',
    myTeam: '我的团队',
    total: '总人数',
    user: '用户',
    contribution: '贡献(任务)',
    status: '状态'
  },
  // 我的页面
  userPage: {
    noLogin: '未登录',
    id: '编号',
    account: '我的账户',
    freezeAmount: '冻结金额',
    withdrawal: '提现',
    recharge: '充值',
    invitation: '邀请你的好友',
    invitationText: '好友注册后赚取的每笔利润，您都将获得相应比例的佣金',
    nowInvitation: '立即邀请',
    userInfo: '个人信息',
    financial: '资金明细',
    rechargeRecord: '充值记录',
    withdrawalRecord: '提现记录',
    lang: '语言',
    out: '退出登录',
    copy: '复制成功',
    load: '加载中...',
    outMsg: '确定要退出登录吗?'
  },
  // 提现页面
  withdrawalPage: {
    title: '提现',
    mode: '支付方式',
    address: '提现地址',
    balance: '可用余额',
    money: '提现金额',
    account: '实际到账',
    serviceCharge: '提现手续费为',
    submit: '提交',
    ipt: '提现金额不可大于可用余额',
    isSub: '已提交申请',
    isAddress: '请先前往绑定提现地址',
    noAccount: '可提现金额不足'
  },
  // 充值页面
  rechargePage: {
    title: '充值',
    submit: '提交',
    reMode: '充值方式',
    reMoney: '充值金额',
    certificate: '上传凭证',
    moneyMsg: '请输入金额',
    qrCode: '扫码充值',
    copyClick: '点击复制',
    copy: '复制成功',
    success: '充值成功',
    submitMsg: '提交成功,审核中',
    load: '上传中...',
    loadSucess: '上传成功',
    loadError: '上传失败,请重试',
    isPz: '请上传凭证',
    rechargeMsg: '请选择以下方式转账对等金额,避免财务检查延误',
    detail: '充值详情',
    orderId: '订单号',
    time: '时间',
    plase: '请使用账户',
    tran: '转入',
    toAcc: '到帐户',
    newAccount: '目前收到的金额',
    results: '获得结果'
  },
  //邀请好友页
  InvitePage: {
    title: '邀请好友',
    InviteCode: '邀请码',
    InviteText: '邀请好友，享受大额收益 快去邀请吧',
    copyClick: '点击复制',
    copy: '复制成功',
  },
  // 个人信息页
  userInfoPage: {
    title: '个人信息',
    phone: '手机号',
    pasd: '登录密码',
    edit: '修改',
    yPasd: '请输入原密码',
    yPasdMsg: '原密码不能为空!',
    newPasd: '请输入新密码',
    newPasdMsg: '新密码不能为空',
    nowPasd: '确认密码',
    nowPasdMsg: '确认密码不能为空',
    close: '取消',
    submit: '确定',
    address: '请输入修改地址',
    sucess: '修改提现地址成功',
    goLogin: '修改密码成功,请重新登录',
  },
  // 资金明细页
  financialPage: {
    title: '资金明细',
    noMore: '没有更多了',
    amount: '金额',
    recharge: '充值',
    orderSy: '订单收益',
    distributionSy: '分销收益',
    other: '其他',
    payment: '支付订单',
    withdrawal: '提现',
    frozen: '冻结',
    time: '时间',
    noData: '暂无数据',
    refresh: '刷新成功',
    Withdrawn: '已提现',
    shsb: '审核失败',
    shz: '审核中'
  },
  // 充值记录页面
  rechargeRecordPage: {
    title: '充值记录',
    noMore: '没有更多了',
    amount: '金额',
    pendingPayment: '待支付',
    paymentSec: '支付成功',
    paymentFail: '支付失败',
    refund: '退款',
    time: '时间',
    noData: '暂无数据',
    refresh: '刷新成功',
    textMsg: '请务必按照付款金额后面的小数点转账,否则无法到账!'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: '提现记录',
    noMore: '没有更多了',
    amount: '金额',
    withdrawal: '提现',
    time: '时间',
    noData: '暂无数据',
    refresh: '刷新成功',
  }
}